import gql from 'graphql-tag';

const VENDORS_QUERY = gql`
  query Vendors {
    vendorsSearch {
      id
      name
      categories
    }
  }
`;

const EVENT_INVITE_UPDATE_MUTATION = gql`
  mutation UpdateEventInvite($input: EventInviteUpdateMutationInput!) {
    eventInviteUpdate(input: $input) {
      eventInvite {
        id
        status
        event {
          id
        }
      }
    }
  }
`;

const EVENT_VENDORS_QUERY = gql`
  query EventVendors($filters: EventVendorFilterInput) {
    currentActor {
      id
      eventVendors(filters: $filters) {
        totalRecords
        edges {
          cursor
          node {
            id
            status
            eventName
            notes
            internalNotes
            primaryPointOfContactActor {
              id
              firstName
              lastName
              name
              initials
              avatarUrl
              email
              phoneNumber
              instagramHandle
              actorType
            }
            secondaryPointOfContactActor {
              id
              firstName
              lastName
              name
              initials
              avatarUrl
              email
              phoneNumber
              instagramHandle
              actorType
            }
            dayOfPointOfContactActor {
              id
              firstName
              lastName
              name
              initials
              avatarUrl
              email
              phoneNumber
              instagramHandle
              actorType
            }
            vendor {
              id
              name
              phoneNumber
              instagramHandle
              email
              websiteUrl
              teamMemberActorRoles {
                id
                name
              }
              address {
                id
                formatted
                street1
                street2
                city
                usState {
                  id
                  abbreviation
                }
                zipCode
              }
              teamMemberActors {
                edges {
                  node {
                    id
                    firstName
                    lastName
                    name
                    initials
                    avatarUrl
                    email
                    phoneNumber
                    instagramHandle
                    actorType
                  }
                }
              }
              primaryPointOfContactActor {
                id
                firstName
                lastName
                name
                initials
                avatarUrl
                email
                phoneNumber
                instagramHandle
                actorType
              }
              internalNotes
              avatarUrl
              categories
              isUpdatableByCurrentActor
              reviewSources {
                id
                name
              }
            }
          }
        }
      }
    }
    currentVendor {
      id
      editableReviewSources {
        id
        name
      }
      reviewSourceOptions {
        id
        name
      }
    }
  }
`;

const EVENT_VENDOR_ADD_MUTATION = gql`
  mutation AddVendorToEvent($input: EventVendorAddMutationInput!) {
    eventVendorAdd(input: $input) {
      eventVendor {
        id
        status
        primaryPointOfContactActor {
          id
          firstName
          lastName
          email
          instagramHandle
          phoneNumber
          name
        }
        vendor {
          id
          name
          teamMemberActorRoles {
            id
            name
          }
        }
      }
    }
  }
`;

const VENDOR_CREATE_MUTATION = gql`
  mutation CreateVendor($input: VendorCreateMutationInput!) {
    vendorCreate(input: $input) {
      vendor {
        id
        name
        address {
          id
          street1
          street2
          city
          usState {
            id
            name
          }
          zipCode
        }
      }
    }
  }
`;

export {
  VENDORS_QUERY,
  EVENT_INVITE_UPDATE_MUTATION,
  EVENT_VENDORS_QUERY,
  EVENT_VENDOR_ADD_MUTATION,
  VENDOR_CREATE_MUTATION,
};
