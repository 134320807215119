import React, { useEffect, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import { Avatar } from 'primereact/avatar';
import { FileUpload } from 'primereact/fileupload';

import { showSuccessToast, showErrorToast } from 'utils/toastUtils';
import TextInput from 'components/Form/TextInput';
import EmailInput from 'components/Form/EmailInput';
import InstagramHandleInput from 'components/Form/InstagramHandleInput';
import PhoneNumberInput from 'components/Form/PhoneNumberInput';
import { isSubmittableArea } from 'utils/formUtils';
import { TEAM_MEMBER_ACTOR_UPDATE_MUTATION } from './graphql';

function UpdateTeamMemberForm({
  toastRef, teamMemberActor, stopEditing, refetch, eventVendor,
}) {
  const formRef = useRef();

  const {
    control,
    getValues,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      firstName: teamMemberActor.firstName,
      lastName: teamMemberActor.lastName,
      email: teamMemberActor.email,
      phoneNumber: teamMemberActor.phoneNumber,
      instagramHandle: teamMemberActor.instagramHandle,
    },
  });

  const [updateTeamMemberActorMutation] = useMutation(TEAM_MEMBER_ACTOR_UPDATE_MUTATION, {
    onCompleted: async () => {
      await refetch({ variables: { filters: { id: { eq: eventVendor.id } } } });
      stopEditing();
      showSuccessToast(toastRef, 'Successfully updated team member!');
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, `Error updating team member: ${message}`)
      ));
    },
  });

  useEffect(() => {
    const handleBlur = async (e) => {
      if (isSubmittableArea(formRef, e)) {
        if (isDirty) {
          await updateTeamMemberActorMutation({
            variables: {
              input: {
                id: teamMemberActor.id,
                firstName: getValues('firstName'),
                lastName: getValues('lastName'),
                email: getValues('email'),
                instagramHandle: getValues('instagramHandle'),
                phoneNumber: getValues('phoneNumber'),
              },
            },
          });
        }
      }
    };

    document.addEventListener('mousedown', handleBlur);

    return () => {
      document.removeEventListener('mousedown', handleBlur);
    };
  }, [isDirty]);

  return (
    <div ref={formRef} className="text-center">
      <p className="m-0 mt-4 text-gray-800 font-semibold">{teamMemberActor.roles.join(', ')}</p>
      <div className="mt-4">
        <div className="w-full flex justify-center mb-1">
          <div className="flex flex-col">
            <Avatar image={teamMemberActor?.avatarUrl} size="xlarge" shape="circle" />
            <div className="flex w-full justify-center">
              <FileUpload
                mode="basic"
                chooseOptions={{ icon: 'pi pi-pencil', iconOnly: true, className: '-mt-6 border-circle p-1 button-icon-only' }}
                onSelect={({ files }) => updateTeamMemberActorMutation({ id: teamMemberActor.id, avatar: files[0] })}
              />
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center mt-2">
          <TextInput
            control={control}
            className="w-1/2 mr-1"
            inputClassName="text-sm"
            name="firstName"
          />
          <TextInput
            control={control}
            className="w-1/2"
            inputClassName="text-sm"
            name="lastName"
          />
        </div>
        <div className="w-full flex justify-center">
          <div>
            <EmailInput
              control={control}
              inputClassName="text-sm"
              name="email"
            />
            <PhoneNumberInput
              control={control}
              inputClassName="text-sm"
              name="phoneNumber"
            />
            <InstagramHandleInput
              control={control}
              inputClassName="text-sm"
              name="instagramHandle"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateTeamMemberForm;
