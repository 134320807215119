import gql from 'graphql-tag';
import PAGE_INFO_FIELDS from 'graphql/fragments/pageInfo';

const VENDORS_QUERY = gql`
  ${PAGE_INFO_FIELDS}

  query Vendors($first: Int, $last: Int, $before: String, $after: String, $filters: EventVendorFilterInput) {
    currentActor {
      id
      eventVendors(first: $first, last: $last, before: $before, after: $after, filters: $filters) {
        totalRecords
        pageInfo {
          ...PageInfoFields
        }
        edges {
          cursor
          node {
            id
            eventName
            notes
            internalNotes
            primaryPointOfContactActor {
              id
              firstName
              lastName
              name
              initials
              avatarUrl
              email
              phoneNumber
              instagramHandle
              actorType
            }
            secondaryPointOfContactActor {
              id
              firstName
              lastName
              name
              initials
              avatarUrl
              email
              phoneNumber
              instagramHandle
              actorType
            }
            dayOfPointOfContactActor {
              id
              firstName
              lastName
              name
              initials
              avatarUrl
              email
              phoneNumber
              instagramHandle
              actorType
            }
            vendor {
              id
              name
              phoneNumber
              instagramHandle
              email
              websiteUrl
              teamMemberActorRoles {
                id
                name
              }
              address {
                id
                formatted
                street1
                street2
                city
                usState {
                  id
                  abbreviation
                }
                zipCode
              }
              teamMemberActors {
                edges {
                  node {
                    id
                    firstName
                    lastName
                    name
                    initials
                    avatarUrl
                    email
                    phoneNumber
                    instagramHandle
                    actorType
                  }
                }
              }
              primaryPointOfContactActor {
                id
                firstName
                lastName
                name
                initials
                avatarUrl
                email
                phoneNumber
                instagramHandle
                actorType
              }
              internalNotes
              avatarUrl
              categories
              isUpdatableByCurrentActor
              reviewSources {
                id
                name
              }
            }
          }
        }
      }
    }
    currentVendor {
      id
      editableReviewSources {
        id
        name
      }
      reviewSourceOptions {
        id
        name
      }
    }
  }
`;

export {
  VENDORS_QUERY,
};
