import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';

import EditableTextArea from 'components/Form/EditableFields/TextArea';
import Dropdown from 'components/Form/Dropdown';
import { mapInfo } from 'utils/displayUtils';
import { showErrorToast } from 'utils/toastUtils';
import { cursorClassName } from 'utils/styleUtils';
import { emailLink, phoneLink, instagramLink } from 'utils/stringUtils';
import UpdateVendorAddress from './UpdateVendorAddress';
import UpdateVendorEmail from './UpdateVendorEmail';
import UpdateVendorWebsiteUrl from './UpdateVendorWebsiteUrl';
import UpdateVendorPhone from './UpdateVendorPhone';
import UpdateVendorInstagram from './UpdateVendorInstagram';
import UpdateVendorReviewSources from './UpdateVendorReviewSources';
import UpdateEventVendorContacts from './UpdateEventVendorContacts';
import UpdateTeamMember from './UpdateTeamMemberForm';

import { EVENT_VENDOR_UPDATE_MUTATION, VENDOR_UPDATE_MUTATION } from './graphql';

const mappedRoleToDisplay = {
  defaultTaskAssigneeActor: 'Tasks',
  primaryPointOfContactActor: 'Primary',
  secondaryPointOfContactActor: 'Secondary',
  dayOfPointOfContactActor: 'Day of',
};

function VendorProfile({
  toastRef, vendor, eventVendors, refetchVendors, currentVendor, showCreateTeamMemberModal, setShowCreateTeamMemberModal, eventVendor, setEventVendor,
}) {
  const [updatedVendor, setUpdatedVendor] = useState(vendor);
  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const [isEditingVendorInternalNotes, setIsEditingVendorInternalNotes] = useState(false);
  const [isEditingEventVendorInternalNotes, setIsEditingEventVendorInternalNotes] = useState(false);
  const [isEditingAddress, setIsEditingAddress] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [isEditingPhone, setIsEditingPhone] = useState(false);
  const [isEditingWebsiteUrl, setIsEditingWebsiteUrl] = useState(false);
  const [isEditingInstagram, setIsEditingInstagram] = useState(false);
  const [isEditingContacts, setIsEditingContacts] = useState(false);
  const [isEditingContactActorId, setIsEditingContactActorId] = useState(null);
  const [isEditingReviewSources, setIsEditingReviewSources] = useState(false);
  const [mappedContactActors, setMappedContactActors] = useState([]);
  const [isEditable, setIsEditable] = useState(false);

  const {
    email,
    phoneNumber,
    instagramHandle,
    websiteUrl,
    address,
    reviewSources,
    isUpdatableByCurrentActor,
  } = updatedVendor;

  const anyVendorInfo = email || phoneNumber || instagramHandle || websiteUrl || address?.street1 || reviewSources?.length;

  const {
    control, getValues, setValue, watch,
  } = useForm({
    defaultValues: {
      notes: eventVendor?.notes,
      internalNotes: eventVendor?.internalNotes,
      isUpdatableByCurrentActor,
      eventVendorId: eventVendor?.id,
    },
  });

  /* eslint-disable no-param-reassign */
  const mapContactActors = () => reduce({
    primaryPointOfContactActor: eventVendor.primaryPointOfContactActor,
    secondaryPointOfContactActor: eventVendor.secondaryPointOfContactActor,
    dayOfPointOfContactActor: eventVendor.dayOfPointOfContactActor,
  }, (mappedContacts, actor, contactRole) => {
    if (actor) {
      const role = mappedRoleToDisplay[contactRole];
      const alreadyMappedContact = mappedContacts.find((contact) => contact.id === actor.id);

      if (alreadyMappedContact) {
        if (role) { alreadyMappedContact.roles.push(role); }
      } else {
        if (role) { actor.roles = [role]; }

        mappedContacts.push(actor);
      }
    }

    return mappedContacts;
  }, []);
  /* eslint-enable no-param-reassign */

  useEffect(() => {
    if (!showCreateTeamMemberModal) { setIsEditingContacts(false); }
  }, [showCreateTeamMemberModal]);

  useEffect(() => {
    setMappedContactActors(mapContactActors());
    setUpdatedVendor(eventVendor.vendor);
    setValue('notes', eventVendor.notes);
    setValue('eventVendorInternalNotes', eventVendor.internalNotes);
  }, [eventVendor]);

  useEffect(() => {
    setMappedContactActors(mapContactActors());
  }, [eventVendors]);

  useEffect(() => {
    setValue('vendorInternalNotes', updatedVendor.internalNotes);
    setIsEditingAddress(false);
    setIsEditingNotes(false);
    setIsEditingVendorInternalNotes(false);
    setIsEditingAddress(false);
    setIsEditingEmail(false);
    setIsEditingWebsiteUrl(false);
    setIsEditingPhone(false);
    setIsEditingInstagram(false);
    setIsEditingContacts(false);
    setIsEditingReviewSources(false);
    setIsEditingContactActorId(false);
  }, [updatedVendor]);

  useEffect(() => {
    if (
      !isUpdatableByCurrentActor
      || isEditingNotes
      || isEditingVendorInternalNotes
      || isEditingAddress
      || isEditingEmail
      || isEditingWebsiteUrl
      || isEditingPhone
      || isEditingInstagram
      || isEditingContacts
      || isEditingReviewSources
      || isEditingContactActorId
    ) {
      setIsEditable(false);
    } else {
      setIsEditable(isUpdatableByCurrentActor);
    }
  }, [
    isEditingNotes,
    isEditingVendorInternalNotes,
    isEditingEventVendorInternalNotes,
    isEditingAddress,
    isEditingEmail,
    isEditingWebsiteUrl,
    isEditingPhone,
    isEditingInstagram,
    isEditingContacts,
    isEditingReviewSources,
    isEditingContactActorId,
  ]);

  const [updateVendorMutation] = useMutation(VENDOR_UPDATE_MUTATION, {
    onCompleted: ({ vendorUpdate }) => {
      setUpdatedVendor(vendorUpdate.vendor);
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, `Error updating vendor: ${message}`)
      ));
    },
  });

  const [updateEventVendorMutation] = useMutation(EVENT_VENDOR_UPDATE_MUTATION, {
    onCompleted: ({ eventVendorUpdate }) => {
      setValue('notes', eventVendorUpdate.eventVendor.notes);
      setValue('eventVendorInternalNotes', eventVendorUpdate.eventVendor.internalNotes);
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, `Error updating vendor: ${message}`)
      ));
    },
  });

  const onStopEditContactActor = () => {
    setIsEditingContactActorId(null);
    setIsEditingContacts(false);
  };

  const contacts = () => (
    <div className="grid sm:grid-cols-3 xs:grid-cols-2 xs:gap-4">
      {
        mappedContactActors.map((contactActor) => {
          if (isEditingContactActorId === contactActor.id) {
            return (
              <UpdateTeamMember
                teamMemberActor={contactActor}
                vendorId={vendor.id}
                toastRef={toastRef}
                stopEditing={onStopEditContactActor}
                eventVendor={eventVendor}
                refetch={refetchVendors}
              />
            );
          }
          return (
            <div className="text-center w-full flex justify-center">
              <div className="h-fit w-fit text-nowrap" onClick={() => (isEditable ? setIsEditingContactActorId(contactActor.id) : {})}>
                <p className="m-0 mt-4 text-gray-800 font-semibold">{contactActor.roles.join(', ')}</p>
                <div className="mt-4 text-sm">
                  <div className="w-full flex justify-center mb-2">
                    <Avatar
                      label={contactActor.initials}
                      image={contactActor.avatarUrl}
                      size="large"
                      shape="circle"
                      className={`text-white ${contactActor.avatarUrl ? '' : 'bg-info'}`}
                    />
                  </div>
                  <p key={`${contactActor.id}-actor-name`}>{ contactActor.name }</p>
                  <p className="text-sm">{ contactActor.actorType === 'TEAM_MEMBER' ? 'Admin' : 'Group' }</p>
                  <p className="mb-2">
                    {
                      emailLink({
                        email: contactActor.email,
                        className: 'text-sm',
                        iconClassName: 'text-sm',
                        showEmpty: isUpdatableByCurrentActor,
                        showIcon: true,
                        shouldLink: !isEditable,
                      })
                    }
                  </p>
                  <p className="mb-2">
                    {
                      phoneLink({
                        phone: contactActor.phoneNumber,
                        className: 'text-sm',
                        iconClassName: 'text-sm',
                        showEmpty: isUpdatableByCurrentActor,
                        showIcon: true,
                        shouldLink: !isEditable,
                      })
                    }
                  </p>
                  {
                    instagramLink({
                      instagramHandle: contactActor.instagramHandle,
                      className: 'text-sm',
                      iconClassName: 'text-sm',
                      showEmpty: isUpdatableByCurrentActor,
                      showIcon: true,
                      shouldLink: !isEditable,
                    })
                  }
                </div>
              </div>
            </div>
          );
        })
      }
    </div>
  );

  const setIsEditingContactActors = (e) => {
    if (isUpdatableByCurrentActor && !(e.target.className || '').includes('dropdown')) { setIsEditingContacts(true); }
  };

  const mappedTeamMembers = () => {
    if (isEmpty(mappedContactActors) && !isUpdatableByCurrentActor) { return null; }

    return (
      <div
        className={`border-gray-200 ${cursorClassName(isEditable && isUpdatableByCurrentActor)}`}
        onClick={setIsEditingContactActors}
      >
        <div className="flex items-center mt-4">
          <p className="m-0 text-lg text-gray-800 font-semibold mr-4">Event Info</p>
          {
            eventVendors.length > 1 ? (
              <Dropdown
                control={control}
                className="w-unset min-w-fit mb-0"
                name="eventVendorId"
                value={watch('eventVendorId')}
                options={eventVendors.map((ev) => ({ label: ev.eventName, value: ev.id }))}
                onChange={(eventVendorId) => setEventVendor(eventVendors.find((ev) => ev.id === eventVendorId))}
              />
            ) : null
          }
        </div>
        <div className="flex w-full justify-between items-center">
          <p className="m-0 mt-4 text-gray-800 font-semibold">Contacts</p>
          <Button
            rounded
            className={isEditingContacts ? 'h-6 w-6 cursor-pointer text-xs' : 'hidden'}
            icon="pi pi-plus"
            onClick={() => setShowCreateTeamMemberModal(true)}
          />
        </div>
        {
          (isEditingContacts && !isEditingContactActorId) ? (
            <UpdateEventVendorContacts
              refetch={refetchVendors}
              vendor={updatedVendor}
              eventVendor={eventVendor}
              toastRef={toastRef}
              setIsEditing={setIsEditingContacts}
              hideOnBlur={!showCreateTeamMemberModal}
            />
          ) : (
            <div>
              {
                isEmpty(mappedContactActors) ? (
                  <p className="text-xs italic mt-2">Add Contacts</p>
                ) : (
                  contacts()
                )
              }
            </div>
          )
        }
      </div>
    );
  };

  const updateVendor = (data) => {
    updateVendorMutation({
      variables: { input: { id: vendor.id, internalNotes: data.vendorInternalNotes } },
    });
  };

  const updateEventVendor = (data) => {
    updateEventVendorMutation({
      variables: { input: { id: eventVendor.id, notes: data.notes, internalNotes: data.eventVendorInternalNotes } },
    });
  };

  const notesField = () => (
    <div className="mt-4 border-gray-200">
      <div className="flex items-center">
        <p className="m-0 my-4 text-gray-800 font-semibold">Public Notes</p>
        <Tooltip target=".dropdown-tooltip-icon-notes" />
        <i
          className="dropdown-tooltip-icon-notes pi pi-eye ml-2"
          data-pr-tooltip="Visible to everyone"
          data-pr-position="top"
          style={{ cursor: 'pointer' }}
        />
      </div>
      <EditableTextArea
        updatable
        formats={['bold', 'italic', 'underline', 'strike']}
        name="notes"
        updateFieldName="notes"
        textViewClassName="mb-0"
        isEditing={isEditingNotes}
        setIsEditing={setIsEditingNotes}
        onUpdate={updateEventVendor}
        control={control}
        setValue={setValue}
        getValues={getValues}
        placeholder="Add notes..."
      />
    </div>
  );

  const vendorInternalNotesField = () => (
    <div className="w-full h-full">
      <div className="flex items-center mb-2">
        <p className="text-gray-800 font-semibold">Internal Notes</p>
        <Tooltip target=".dropdown-tooltip-icon-internal-notes" />
        <i
          className="dropdown-tooltip-icon-internal-notes pi pi-eye-slash ml-2"
          data-pr-tooltip={`Only visible to ${currentVendor?.name || 'you'}`}
          data-pr-position="top"
          style={{ cursor: 'pointer' }}
        />
      </div>
      <EditableTextArea
        formats={['bold', 'italic', 'underline', 'strike']}
        name="vendorInternalNotes"
        updateFieldName="vendorInternalNotes"
        textViewClassName="mb-0"
        updatable
        isEditing={isEditingVendorInternalNotes}
        setIsEditing={setIsEditingVendorInternalNotes}
        onUpdate={updateVendor}
        control={control}
        setValue={setValue}
        getValues={getValues}
        placeholder="Add notes..."
      />
    </div>
  );

  const eventVendorInternalNotesField = () => (
    <div className="mt-4 border-gray-200">
      <div className="flex items-center">
        <p className="m-0 my-4 text-gray-800 font-semibold">Internal Notes</p>
        <Tooltip target=".dropdown-tooltip-icon-internal-notes" />
        <i
          className="dropdown-tooltip-icon-internal-notes pi pi-eye-slash ml-2"
          data-pr-tooltip={`Only visible to ${currentVendor?.name || 'you'}`}
          data-pr-position="top"
          style={{ cursor: 'pointer' }}
        />
      </div>
      <EditableTextArea
        formats={['bold', 'italic', 'underline', 'strike']}
        name="eventVendorInternalNotes"
        updateFieldName="eventVendorInternalNotes"
        textViewClassName="mb-0"
        updatable
        isEditing={isEditingEventVendorInternalNotes}
        setIsEditing={setIsEditingEventVendorInternalNotes}
        onUpdate={updateEventVendor}
        control={control}
        setValue={setValue}
        getValues={getValues}
        placeholder="Add notes..."
      />
    </div>
  );

  const mappedReviewSources = () => (
    updatedVendor.reviewSources.length || isUpdatableByCurrentActor ? (
      <div className="flex items-center">
        <i className="pi pi-star mr-2" />
        <div className={isEditable ? 'cursor-pointer' : ''} onClick={() => setIsEditingReviewSources(currentVendor && isUpdatableByCurrentActor)}>
          {
            updatedVendor.reviewSources.length ? (
              <div className="text-sm">{ updatedVendor.reviewSources.map((reviewSource) => reviewSource.name).join(', ') }</div>
            ) : (
              <div className="text-xs italic">Add Review Sources</div>
            )
          }
        </div>
      </div>
    ) : null
  );

  const mappedVendorInfo = () => (
    <div className="border-b pb-4 border-gray-200">
      <div className="flex items-center justify-between">
        <div className="block md:flex items-center justify-between grid xxs:grid-cols-1 md:grid-cols-2 gap-16 p-3 w-full">
          <div className="xxs:border-r-0 md:border-r border-gray-300 mr-4">
            <p className="mb-2 text-gray-800 font-semibold">Vendor Info</p>
            {
                (anyVendorInfo || isUpdatableByCurrentActor) ? (
                  <>
                    {
                    isEditingEmail ? (
                      <UpdateVendorEmail
                        setUpdatedVendor={setUpdatedVendor}
                        setIsEditing={setIsEditingEmail}
                        vendor={updatedVendor}
                        toastRef={toastRef}
                      />
                    ) : (
                      <div className={isEditable ? 'cursor-pointer' : ''} onClick={() => setIsEditingEmail(isUpdatableByCurrentActor)}>
                        {
                          mapInfo({
                            data: updatedVendor,
                            field: 'email',
                            icon: 'envelope',
                            className: 'text-default text-sm mb-2',
                            shouldLink: !isUpdatableByCurrentActor,
                            showEmpty: isUpdatableByCurrentActor,
                          })
                        }
                      </div>
                    )
                  }
                    {
                    isEditingPhone ? (
                      <UpdateVendorPhone
                        setIsEditing={setIsEditingPhone}
                        setUpdatedVendor={setUpdatedVendor}
                        vendor={updatedVendor}
                        toastRef={toastRef}
                      />
                    ) : (
                      <div className={isEditable ? 'cursor-pointer' : ''} onClick={() => setIsEditingPhone(isUpdatableByCurrentActor)}>
                        {
                          mapInfo({
                            data: updatedVendor,
                            field: 'phoneNumber',
                            icon: 'phone',
                            className: 'text-default text-sm mb-2',
                            shouldLink: !isUpdatableByCurrentActor,
                            showEmpty: isUpdatableByCurrentActor,
                          })
                        }
                      </div>
                    )
                  }
                    {
                    isEditingInstagram ? (
                      <UpdateVendorInstagram
                        setIsEditing={setIsEditingInstagram}
                        setUpdatedVendor={setUpdatedVendor}
                        vendor={updatedVendor}
                        toastRef={toastRef}
                      />
                    ) : (
                      <div className={isEditable ? 'cursor-pointer' : ''} onClick={() => setIsEditingInstagram(isUpdatableByCurrentActor)}>
                        {
                          mapInfo({
                            data: updatedVendor,
                            field: 'instagramHandle',
                            icon: 'instagram',
                            className: 'text-default text-sm mb-2',
                            shouldLink: !isUpdatableByCurrentActor,
                            showEmpty: isUpdatableByCurrentActor,
                          })
                        }
                      </div>
                    )
                  }
                    {
                    isEditingWebsiteUrl ? (
                      <UpdateVendorWebsiteUrl
                        setIsEditing={setIsEditingWebsiteUrl}
                        setUpdatedVendor={setUpdatedVendor}
                        vendor={updatedVendor}
                        toastRef={toastRef}
                      />
                    ) : (
                      <div className={isEditable ? 'cursor-pointer' : ''} onClick={() => setIsEditingWebsiteUrl(isUpdatableByCurrentActor)}>
                        {
                          mapInfo({
                            data: updatedVendor,
                            field: 'websiteUrl',
                            icon: 'globe',
                            className: 'text-default text-sm mb-2',
                            shouldLink: !isUpdatableByCurrentActor,
                            showEmpty: isUpdatableByCurrentActor,
                          })
                        }
                      </div>
                    )
                  }
                    {
                    isEditingAddress ? (
                      <UpdateVendorAddress
                        setIsEditing={setIsEditingAddress}
                        vendor={updatedVendor}
                        setUpdatedVendor={setUpdatedVendor}
                        toastRef={toastRef}
                      />
                    ) : (
                      <div className={isEditable ? 'cursor-pointer' : ''} onClick={() => setIsEditingAddress(isUpdatableByCurrentActor)}>
                        {
                          mapInfo({
                            data: updatedVendor.address || {},
                            field: 'address',
                            isFormatted: true,
                            icon: 'map-marker',
                            className: 'text-default text-sm mb-2',
                            shouldLink: !isUpdatableByCurrentActor,
                            showEmpty: isUpdatableByCurrentActor,
                          })
                        }
                      </div>
                    )
                  }
                    {
                    isEditingReviewSources ? (
                      <div className="flex items-center">
                        <i className="pi pi-star mr-2" />
                        <UpdateVendorReviewSources
                          setIsEditing={setIsEditingReviewSources}
                          vendor={updatedVendor}
                          setUpdatedVendor={setUpdatedVendor}
                          reviewSources={updatedVendor.reviewSources}
                          editableReviewSources={currentVendor.editableReviewSources}
                          reviewSourceOptions={currentVendor.reviewSourceOptions}
                          refetchVendor={refetchVendors}
                          toastRef={toastRef}
                        />
                      </div>
                    ) : mappedReviewSources()
                  }
                  </>
                ) : <p className="text-sm">No info available</p>
              }
          </div>
          <div className="w-full h-full">
            {vendorInternalNotesField({ notes: vendor.internalNotes })}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="event-vendor-profile " key={`${vendor.id}-event-vendor-profile`}>
      { mappedVendorInfo() }
      { mappedTeamMembers() }
      { notesField() }
      { eventVendorInternalNotesField() }
    </div>
  );
}

export default VendorProfile;
