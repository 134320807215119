import React, { useRef, useState, useEffect } from 'react';
import { Tree } from 'primereact/tree';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';

function Filters({
  filtersButtonRef,
  onClearFilters = () => {},
  onUpdateFilters = () => {},
  setShowFilters,
  filterOptions,
  filterItemTemplate = null,
  filtersLocalStorageName = null,
  className = '',
  setFilters,
  showFilters,
  selectedKeys,
  setSelectedKeys,
  selectedKeysLocalStorageName = null,
}) {
  const [firstRender, setFirstRender] = useState(true);
  const filtersRef = useRef();

  const clearFilters = () => {
    if (selectedKeysLocalStorageName) { localStorage.removeItem(selectedKeysLocalStorageName, {}); }
    if (filtersLocalStorageName) { window.localStorage.removeItem(filtersLocalStorageName, {}); }

    setFilters({});
    setSelectedKeys([]);
    onClearFilters();
  };

  /* eslint-disable no-param-reassign */
  const updateFilters = () => {
    const selectedFilters = keys(selectedKeys);

    if ((selectedFilters).find((filter) => filter === 'clear-filters')) {
      return clearFilters();
    }

    let updatedFilters = selectedFilters.reduce((newFilters, filter) => {
      if (!filter.startsWith('filter-type')) {
        const splitFilterString = filter.split('-');
        const filterName = splitFilterString[0];
        const filterValue = splitFilterString[1];

        if (!filterValue) {
          newFilters[filterName] = true;
        } else if (newFilters[filterName]) {
          newFilters[filterName].push(filterValue);
        } else {
          newFilters[filterName] = [filterValue];
        }
      }

      return newFilters;
    }, {});

    updatedFilters = onUpdateFilters({ updatedFilters }) || updatedFilters;

    return updatedFilters;
  };
  /* eslint-enable no-param-reassign */

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);

      if (!isEmpty(selectedKeys)) { return; }
    }

    const updatedFilters = updateFilters() || {};

    setFilters(updatedFilters);

    if (filtersLocalStorageName) { localStorage.setItem(filtersLocalStorageName, JSON.stringify(updatedFilters)); }
    if (selectedKeysLocalStorageName) { localStorage.setItem(selectedKeysLocalStorageName, JSON.stringify(selectedKeys)); }
  }, [selectedKeys]);

  const handleBlur = (event) => {
    if (filtersButtonRef.current?.contains(event.target)) { return; }

    if ((filtersRef.current && !filtersRef.current.contains(event.target) && !event.target.classList.contains('p-checkbox-icon'))) {
      setShowFilters(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleBlur);
  });

  const defaultFilterItemTemplate = (node) => (
    <div className="flex items-center">
      <p>{node.label}</p>
    </div>
  );

  const filterOptionsWithClear = () => (
    [{
      key: 'clear-filters',
      label: 'Clear Filters',
      icon: 'pi pi-filter-slash',
      className: 'p-tree-clear-filters-button',
    }].concat(filterOptions)
  );

  if (!showFilters) { return null; }

  return (
    <div ref={filtersRef} className={`flex justify-center w-[17rem] filters relative ${className}`}>
      <Tree
        filter
        className="fixed z-30 p-1 w-[17rem] text-sm overflow-y-auto"
        value={filterOptionsWithClear()}
        nodeTemplate={filterItemTemplate || defaultFilterItemTemplate}
        selectionMode="checkbox"
        selectionKeys={selectedKeys}
        onSelectionChange={({ value }) => setSelectedKeys(value)}
      />
    </div>
  );
}

export default Filters;
