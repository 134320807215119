import React, { useState } from 'react';
import { Card } from 'primereact/card';
import { Tag } from 'primereact/tag';
import { Avatar } from 'primereact/avatar';

import { titleize } from 'utils/stringUtils';
import { CATEGORY_BACKGROUND_COLOR_MAPPING } from 'constants/colors';
import VendorProfileModal from './VendorProfile/Modal';

function VendorCard({
  currentVendor,
  confirmRemoveVendor,
  refetchVendors,
  vendor,
  eventVendors,
  sendInvite,
  toastRef,
}) {
  const [showVendorProfileModal, setShowVendorProfileModal] = useState(false);

  const content = () => (
    <>
      {
        confirmRemoveVendor ? (
          <div className="flex w-full justify-end pr-4 remove-vendor-button" onClick={confirmRemoveVendor}>
            <i className="pi pi-times text-danger remove-vendor-button" />
          </div>
        ) : null
      }
      <div>
        <div className="flex w-full justify-center mb-2">
          <Avatar
            key={`${vendor.id}-avatar`}
            label={vendor.name[0]}
            shape="circle"
            className={`w-24 h-24 text-white ${vendor.avatarUrl ? '' : 'bg-gray'}`}
            image={vendor.avatarUrl}
          />
        </div>
        <p key={`${vendor.id}-vendor-name`} className="font-semibold m-0 mb-1 text-gray-800"><span className="mr-2">{ vendor.name }</span></p>
        {
          vendor.categories.map((category) => (
            <Tag
              key={category}
              value={titleize(category)}
              className={`${CATEGORY_BACKGROUND_COLOR_MAPPING[category] || CATEGORY_BACKGROUND_COLOR_MAPPING.default} mr-1 mb-1`}
            />
          ))
        }
        <div className="font-medium mt-1">{ eventVendors.length > 1 ? `${eventVendors.length} Events` : '1 Event' }</div>
      </div>
    </>
  );

  const onCloseModal = () => {
    refetchVendors();
    setShowVendorProfileModal(false);
  };

  const onClickCard = (e) => {
    if (!e.target.className.includes('remove-vendor-button')) {
      setShowVendorProfileModal(true);
    }
  };

  return (
    <>
      <Card
        key={`${vendor.id}-card`}
        className="vendor-card rounded-md p-6 h-72 relative cursor-pointer text-center"
        onClick={onClickCard}
      >
        { content() }
      </Card>
      <VendorProfileModal
        refetchVendors={refetchVendors}
        show={showVendorProfileModal}
        onHide={onCloseModal}
        currentVendor={currentVendor}
        sendInvite={sendInvite}
        isManagingCurrentVendor={vendor.id === currentVendor?.id}
        eventVendors={eventVendors}
        vendor={vendor}
        toastRef={toastRef}
      />
    </>
  );
}

export default VendorCard;
