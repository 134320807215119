import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Avatar } from 'primereact/avatar';
import { Tag } from 'primereact/tag';

import { titleize } from 'utils/stringUtils';
import { cursorClassName } from 'utils/styleUtils';
import { CATEGORY_BACKGROUND_COLOR_MAPPING } from 'constants/colors';
import EventInviteForm from 'containers/Events/Show/VendorsTab/EventInviteForm';
import VendorProfile from '.';
import UpdateVendorBasicInfo from './UpdateVendorBasicInfo';
import CreateTeamMemberModal from './CreateTeamMemberForm/Modal';

function VendorProfileModal({
  show,
  onHide,
  toastRef,
  sendInvite,
  vendor,
  eventVendors,
  currentVendor,
  updatable,
  refetchVendors,
  isManagingCurrentVendor,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [updatedVendor, setUpdatedVendor] = useState(vendor);
  const [eventVendor, setEventVendor] = useState(eventVendors[0]);
  const [showCreateTeamMemberModal, setShowCreateTeamMemberModal] = useState(false);
  const [isCreatingPrimaryPointOfContact, setIsCreatingPrimaryPointOfContact] = useState(false);

  useEffect(() => {
    setEventVendor(eventVendors[0]);
    setUpdatedVendor(vendor);
  }, [vendor]);

  const onCreateTeamMemberActor = () => {
    setShowCreateTeamMemberModal(false);
  };

  useEffect(() => {
    if (isCreatingPrimaryPointOfContact && eventVendor.primaryPointOfContactActor) {
      sendInvite();
    }

    setIsCreatingPrimaryPointOfContact(false);
  }, [eventVendor]);

  const categoriesMapping = () => (
    updatedVendor.categories.map((category) => (
      <Tag
        key={`${updatedVendor.id}-${category}`}
        value={titleize(category)}
        className={`${CATEGORY_BACKGROUND_COLOR_MAPPING[category] || CATEGORY_BACKGROUND_COLOR_MAPPING.default} mr-1`}
      />
    ))
  );

  const confirmSendInvite = () => {
    if (eventVendor.primaryPointOfContactActor) {
      sendInvite();
    } else {
      setIsCreatingPrimaryPointOfContact(true);
      setShowCreateTeamMemberModal(true);
    }
  };

  useEffect(() => {
    setIsEditing(false);
  }, [updatedVendor]);

  const handleHeaderClick = (e) => {
    if (!updatedVendor.isUpdatableByCurrentActor || e.target.id === 'vendor-profile-modal-send-invite-button') { return null; }

    return setIsEditing(true);
  };

  const modalHeader = () => (
    <div
      className={cursorClassName(updatedVendor.isUpdatableByCurrentActor)}
      onClick={handleHeaderClick}
    >
      {
        isEditing ? (
          <UpdateVendorBasicInfo
            vendor={updatedVendor}
            toastRef={toastRef}
            setIsEditing={setIsEditing}
            setUpdatedVendor={setUpdatedVendor}
          />
        ) : (
          <div className="text-gray-800 flex">
            <div className="flex items-start">
              <Avatar
                key={`${updatedVendor.id}-avatar`}
                label={updatedVendor.name[0]}
                size="xlarge"
                shape="circle"
                className="mr-2 bg-gray text-white"
                image={updatedVendor.avatarUrl}
              />
              <div>
                <div className="flex items-center">
                  <p key={`${updatedVendor.id}-vendor-name`} className="font-semibold m-0 text-gray-800">
                    <span className="mr-2">{ updatedVendor.name }</span>
                  </p>
                  {
                    sendInvite ? (
                      <EventInviteForm
                        event={eventVendor.event}
                        eventVendorOrActor={eventVendor}
                        refetch={refetchVendors}
                        toastRef={toastRef}
                        confirmSendInvite={confirmSendInvite}
                      />
                    ) : null
                  }
                </div>
                <div key={`${updatedVendor.id}-vendor-categories`} className="font-medium -mt-1">{ categoriesMapping() }</div>
              </div>
            </div>
          </div>
        )
      }
    </div>
  );

  return (
    <Dialog
      key={`${updatedVendor.id}-event-vendor-profile-modal`}
      header={modalHeader()}
      headerClassName="pb-4"
      className="xl:w-7/12"
      visible={show}
      onHide={onHide}
      resizable={false}
      draggable={false}
      dismissableMask
      maximizable
    >
      <VendorProfile
        toastRef={toastRef}
        updatable={updatable}
        refetchVendors={refetchVendors}
        vendor={updatedVendor}
        showCreateTeamMemberModal={showCreateTeamMemberModal}
        setShowCreateTeamMemberModal={setShowCreateTeamMemberModal}
        eventVendors={eventVendors}
        eventVendor={eventVendor}
        setEventVendor={setEventVendor}
        currentVendor={currentVendor}
      />
      <CreateTeamMemberModal
        show={showCreateTeamMemberModal}
        onHide={onCreateTeamMemberActor}
        refetch={refetchVendors}
        eventVendor={eventVendor}
        setEventVendor={setEventVendor}
        vendor={updatedVendor}
        isManagingCurrentVendor={isManagingCurrentVendor}
        toastRef={toastRef}
      />
    </Dialog>
  );
}

export default VendorProfileModal;
