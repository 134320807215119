import React from 'react';
import { Dropdown } from 'primereact/dropdown';

function ManageDisplaySettings(props) {
  const {
    sortOptions,
    displaySettings,
    setSelectedSortOption,
  } = props;

  return (
    <div className="flex justify-between items-center text-nowrap mb-4">
      <p className="flex flex-nowrap mr-4">Sort By</p>
      <Dropdown
        value={displaySettings.sortOption}
        onChange={({ value }) => setSelectedSortOption(value)}
        options={sortOptions}
        className="w-full"
      />
    </div>
  );
}

export default ManageDisplaySettings;
