import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { showSuccessToast, showErrorToast } from 'utils/toastUtils';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Avatar } from 'primereact/avatar';
import omit from 'lodash/omit';

import SwitchInput from 'components/Form/SwitchInput';
import EmailInput from 'components/Form/EmailInput';
import TextInput from 'components/Form/TextInput';
import PhoneNumberInput from 'components/Form/PhoneNumberInput';
import InstagramHandleInput from 'components/Form/InstagramHandleInput';
import { CREATE_TEAM_MEMBER_ACTOR_MUTATION } from './graphql';

function CreateTeamMemberForm({
  toastRef, vendor, eventVendor, sendInvite, refetch, hideModal, isManagingCurrentVendor,
}) {
  const [avatar, setAvatar] = useState(null);
  const {
    control, handleSubmit, getValues,
  } = useForm({
    defaultValues: {
      addToEvent: !!eventVendor,
    },
  });

  const [createTeamMemberActorMutation] = useMutation(CREATE_TEAM_MEMBER_ACTOR_MUTATION, {
    onCompleted: async () => {
      await refetch({ variables: { filters: { id: { eq: eventVendor.id } } } });
      hideModal();
      showSuccessToast(toastRef, 'Successfully created team member');
    },
    onError: ({ graphQLErrors }) => (
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, message)
      ))
    ),
  });

  const onSubmit = (values) => {
    const roleIds = isManagingCurrentVendor ? [] : [vendor.teamMemberActorRoles.find((role) => role.name === 'Admin').id];
    createTeamMemberActorMutation({
      variables: {
        input: {
          ...omit(values, 'addToEvent'),
          eventVendorId: getValues('addToEvent') ? eventVendor?.id : null,
          avatar,
          roleIds,
        },
      },
    });
    if (sendInvite) { sendInvite({ eventVendor }); }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex w-full justify-center">
        <div className="flex flex-col mb-4">
          <Avatar image={avatar?.objectURL} size="xlarge" shape="circle" className="w-52 h-52" />
          <div className="flex w-full justify-center">
            <FileUpload
              mode="basic"
              chooseOptions={{ icon: 'pi pi-pencil', iconOnly: true, className: '-mt-4 border-circle p-3 button-icon-only' }}
              onSelect={({ files }) => setAvatar(files[0])}
            />
          </div>
        </div>
      </div>
      <TextInput
        focus
        control={control}
        name="firstName"
        label="First Name"
      />
      <TextInput
        control={control}
        name="lastName"
        label="Last Name"
      />
      <EmailInput
        control={control}
        name="email"
        label="Email"
      />
      <PhoneNumberInput
        control={control}
        name="phoneNumber"
        label="Phone Number"
      />
      <InstagramHandleInput
        control={control}
        name="instagramHandle"
        label="Instagram Handle"
        required={false}
      />
      {
        eventVendor ? (
          <SwitchInput
            label={`Add to ${eventVendor.eventName}?`}
            name="addToEvent"
            className="flex items-center mt-4"
            labelClassName="mr-2 mb-0"
            control={control}
            getValues={getValues}
          />
        ) : null
      }
      <div className="flex w-full justify-center mt-6">
        <Button
          size="small"
          label={sendInvite ? 'Send Invite' : 'Create'}
          icon={sendInvite ? 'pi pi-envelope' : null}
          severity={sendInvite ? 'success' : 'primary'}
          type="submit"
        />
      </div>
    </form>
  );
}

export default CreateTeamMemberForm;
