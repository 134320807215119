import React, { useRef, useState, useEffect } from 'react';
import { Toast as ToastComponent } from 'primereact/toast';

import { titleize } from 'utils/stringUtils';

function Toast({ severity, detail }) {
  const toastRef = useRef(null);
  const [shown, setShown] = useState(false);

  useEffect(() => {
    if (toastRef?.current && !shown && detail) {
      setShown(true);

      toastRef.current.show({
        severity, summary: titleize(severity), detail, life: 3000,
      });
    }
  }, [toastRef?.current]);

  return <ToastComponent ref={toastRef} position="bottom-left" />;
}

export default Toast;
