import React, { useState } from 'react';
import { Tag } from 'primereact/tag';
import StatusTag from 'containers/Events/StatusTag';
import EventInviteModal from './Modal';

function EventInviteForm({
  event,
  eventVendorOrActor,
  refetch,
  toastRef,
  confirmSendInvite,
  setActiveEventVendor = () => {},
}) {
  const [showEventInviteModal, setShowEventInviteModal] = useState(false);
  const [showInviteButton, setShowInviteButton] = useState(false);

  const { isAdmin, status } = eventVendorOrActor;

  const adminTag = () => <Tag icon="pi pi-star" className="bg-white text-gray-800 p-0" value="Admin" />;
  const activeTag = () => <Tag icon="pi pi-check" className="bg-white text-gray-800 p-0" value="Active" />;

  const inviteButton = () => {
    let text = '';
    let icon = 'send';
    let onClick = (e) => {
      e.stopPropagation();
      confirmSendInvite(eventVendorOrActor);
    };

    switch (status) {
      case 'REQUESTED':
        text = 'Respond to request';
        icon = '';
        onClick = () => setShowEventInviteModal(true);
        break;
      case 'INVITED':
      case 'REJECTED':
      case 'REMOVED': {
        text = 'Re-send invite';
        break;
      }
      default: {
        text = 'Send invite';
        break;
      }
    }

    return (
      <StatusTag
        status="ADDED"
        onMouseEnter={() => setShowInviteButton(true)}
        onMouseLeave={() => setShowInviteButton(false)}
        text={text}
        icon={icon}
        show={showInviteButton}
        onClick={onClick}
      />
    );
  };

  const hideEventInviteModal = () => {
    setActiveEventVendor(null);
    setShowEventInviteModal(false);
  };

  if (isAdmin) { return adminTag(); }

  if (status === 'APPROVED') { return activeTag(); }

  return (
    <>
      { inviteButton() }
      <StatusTag
        status={status}
        border={false}
        onMouseEnter={() => (status === 'ADDED' ? {} : setShowInviteButton(true))}
        onMouseLeave={() => (status === 'ADDED' ? {} : setShowInviteButton(false))}
        show={!showInviteButton}
        onClick={(e) => {
          e.stopPropagation();
          confirmSendInvite(eventVendorOrActor);
        }}
      />
      <EventInviteModal
        event={event}
        eventVendorOrActor={eventVendorOrActor}
        status={status}
        show={showEventInviteModal}
        onHide={hideEventInviteModal}
        refetch={refetch}
        toastRef={toastRef}
      />
    </>
  );
}

export default EventInviteForm;
