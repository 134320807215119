const CATEGORY_BACKGROUND_COLOR_MAPPING = {
  CATERING: 'bg-orange-500',
  DESSERT: 'bg-yellow-500',
  BARTENDING: 'bg-cyan-500',
  BEAUTY: 'bg-pink-500',
  PHOTOBOOTH: 'bg-purple-500',
  PHOTOGRAPHY: 'bg-red-500',
  VIDEOGRAPHY: 'bg-teal-500',
  DJ: 'bg-lime-500',
  BAND: 'bg-rose-500',
  PLANNING: 'bg-blue-500',
  TRANSPORTATION: 'bg-indigo-500',
  FLORISTRY: 'bg-green-500',
  RENTAL: 'bg-zinc-500',
  VENUE: 'bg-emerald-500',
  LODGING: 'bg-brown-500',
  DECOR: 'bg-green-500',
  OFFICIANT: 'bg-red-500',
  STATIONARY: 'bg-purple-500',
  OTHER: 'bg-primary-500',
  DEFAULT: 'bg-primary',
};

const CATEGORY_BORDER_COLOR_MAPPING = {
  CATERING: 'border-orange-500',
  DESSERT: 'border-yellow-500',
  BARTENDING: 'border-cyan-500',
  BEAUTY: 'border-pink-500',
  PHOTOBOOTH: 'border-purple-500',
  PHOTOGRAPHY: 'border-red-500',
  VIDEOGRAPHY: 'border-teal-500',
  DJ: 'border-lime-500',
  BAND: 'border-rose-500',
  PLANNING: 'border-blue-500',
  TRANSPORTATION: 'border-indigo-500',
  FLORISTRY: 'border-green-500',
  RENTAL: 'border-zinc-500',
  VENUE: 'border-emerald-500',
  LODGING: 'border-brown-500',
  DECOR: 'border-green-500',
  OFFICIANT: 'border-red-500',
  STATIONARY: 'border-purple-500',
  OTHER: 'border-primary-500',
  DEFAULT: 'border-primary',
};

const CATEGORY_TEXT_COLOR_MAPPING = {
  CATERING: 'text-orange-500',
  DESSERT: 'text-yellow-500',
  BARTENDING: 'text-cyan-500',
  BEAUTY: 'text-pink-500',
  PHOTOBOOTH: 'text-purple-500',
  PHOTOGRAPHY: 'text-red-500',
  VIDEOGRAPHY: 'text-teal-500',
  DJ: 'text-lime-500',
  BAND: 'text-rose-500',
  PLANNING: 'text-blue-500',
  TRANSPORTATION: 'text-indigo-500',
  FLORISTRY: 'text-green-500',
  RENTAL: 'text-zinc-500',
  VENUE: 'text-emerald-500',
  LODGING: 'text-brown-500',
  DECOR: 'text-green-500',
  OFFICIANT: 'text-red-500',
  STATIONARY: 'text-purple-500',
  OTHER: 'text-primary-500',
  DEFAULT: 'text-primary',
};

export {
  CATEGORY_BACKGROUND_COLOR_MAPPING,
  CATEGORY_BORDER_COLOR_MAPPING,
  CATEGORY_TEXT_COLOR_MAPPING,
};
