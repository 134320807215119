import React, { useState } from 'react';
import {
  Route, Routes, Navigate,
} from 'react-router';
import { useQuery } from '@apollo/react-hooks';
import includes from 'lodash/includes';
import App from 'containers/App';
import LandingPage from 'containers/LandingPage';
import Login from 'containers/Auth/Login';
import SignUp from 'containers/Auth/SignUp';
import Logout from 'containers/Auth/Logout';
import SetAuthHeaders from 'containers/Auth/SetAuthHeaders';
import SendResetLink from 'containers/Auth/ResetPassword/SendResetLink';
import UpdatePassword from 'containers/Auth/ResetPassword/UpdatePassword';
import Onboarding from 'containers/Onboarding';
import Events from 'containers/Events/Index';
import Tasks from 'containers/Tasks/Index';
import Vendors from 'containers/Vendors/Index';
import EventShow from 'containers/Events/Show';
import Settings from 'containers/Settings';
import SelectActorType from 'containers/SelectActorType';
import InvalidPage from 'components/InvalidPage';
import CURRENT_USER_QUERY from './graphql';

const SIDEBAR_MENU_ITEMS = [
  {
    name: 'Events',
    icon: 'calendar',
    route: '/app/events',
  },
  {
    name: 'Tasks',
    icon: 'check-square',
    route: '/app/tasks',
  },
  {
    name: 'Vendors',
    icon: 'shop',
    route: '/app/vendors',
  },
];
function Router({ resetGqlCache }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [currentVendor, setCurrentVendor] = useState(null);
  const [currentActor, setCurrentActor] = useState(null);
  const [isOnboarding, setIsOnboarding] = useState(false);
  const [refetchEvents, setRefetchEvents] = useState(false);
  const [canCreateAbilities, setCanCreateAbilities] = useState([]);
  const [canUpdateAbilities, setCanUpdateAbilities] = useState([]);

  const {
    error, refetch: refetchCurrentUserAndVendor,
  } = useQuery(
    CURRENT_USER_QUERY,
    {
      onCompleted: (data) => {
        const { currentAbilities } = data;

        if (!data.currentUser) {
          window.localStorage.removeItem('authHeaders');
        }

        setCurrentUser(data.currentUser);
        setCurrentVendor(data.currentVendor);
        setCurrentActor(data.currentActor);

        if (currentAbilities) {
          setCanCreateAbilities(currentAbilities.create);
          setCanUpdateAbilities(currentAbilities.update);
        }
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const clearCurrentUserData = () => {
    window.localStorage.removeItem('authHeaders');

    resetGqlCache();

    setCurrentUser(null);
    setCurrentVendor(null);
    setCurrentActor(null);
    setCanCreateAbilities([]);
    setCanUpdateAbilities([]);
  };

  if (error || (window.localStorage.getItem('authHeaders') && !currentUser)) { return null; }

  if (!currentUser) {
    return (
      <Routes>
        <Route
          path="/set-auth-headers"
          element={<SetAuthHeaders refetchCurrentUserAndVendor={refetchCurrentUserAndVendor} />}
        />
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login refetchCurrentUserAndVendor={refetchCurrentUserAndVendor} />} />
        <Route path="/sign-up" element={<SignUp refetchCurrentUserAndVendor={refetchCurrentUserAndVendor} />} />
        <Route path="/onboarding" element={<App />}>
          <Route
            path="/onboarding"
            element={(
              <Onboarding
                currentVendor={currentVendor}
                currentUser={currentUser}
                refetchCurrentUserAndVendor={refetchCurrentUserAndVendor}
                setIsOnboarding={setIsOnboarding}
              />
            )}
          />
        </Route>
        <Route path="/" element={<App />}>
          <Route path="/send-reset-password" element={<SendResetLink />} />
          <Route path="/update-password" element={<UpdatePassword />} />
          <Route
            path="/invalid-token"
            element={(
              <InvalidPage
                header="Party foul!"
                subHeader1="This link is invalid or expired."
                subHeader2="Please check your email for the newest invitation."
                imgSrc="/images/party-foul.jpeg"
              />
            )}
          />
        </Route>
      </Routes>
    );
  }

  if (!currentActor) {
    return (
      <Routes>
        <Route path="*" element={<Navigate to="/" />} />
        <Route
          path="/"
          element={(
            <SelectActorType
              currentUser={currentUser}
              refetchCurrentUserAndVendor={refetchCurrentUserAndVendor}
            />
          )}
        />
      </Routes>
    );
  }

  if (isOnboarding) {
    return (
      <Routes>
        <Route path="*" element={<Navigate to="/onboarding" />} />
        <Route path="/onboarding" element={<App />}>
          <Route
            path="/onboarding"
            element={(
              <Onboarding
                currentActorId={currentActor.id}
                currentVendor={currentVendor}
                currentUser={currentUser}
                refetchCurrentUserAndVendor={refetchCurrentUserAndVendor}
                setIsOnboarding={setIsOnboarding}
              />
            )}
          />
        </Route>
      </Routes>
    );
  }

  const can = (action, resource) => {
    const abilities = action === 'create' ? canCreateAbilities : canUpdateAbilities;

    return includes(abilities, resource);
  };

  const eventShow = () => <EventShow canCreateTasks={can('create', 'tasks')} />;
  const settings = () => (
    <Settings
      canUpdateVendor={can('update', 'vendors')}
      canUpdatePermissions={can('update', 'permissions')}
      canUpdateTeamMembers={can('update', 'team_member_actors')}
      canUpdateTeamMemberGroups={can('update', 'team_member_group_actors')}
      currentVendorName={currentVendor?.name}
    />
  );

  return (
    <Routes>
      <Route path="/" element={<App refetchEvents={() => setRefetchEvents(true)} sidebarMenuItems={SIDEBAR_MENU_ITEMS} user={currentUser} />}>
        <Route path="/" element={<Navigate to="/app/events" />} />
        <Route path="/login" element={<Navigate to="/app/events" />} />
        <Route path="/sign-up" element={<Navigate to="/app/events" />} />
        <Route path="/set-auth-headers" element={<Navigate to="/app/events" />} />
        <Route path="/send-reset-password" element={<Navigate to="/app/events" />} />
        <Route path="/onboarding" element={<Navigate to="/app/events" />} />
        <Route path="/create-reset-password" element={<Navigate to="/app/events" />} />
        <Route path="/logout" element={<Logout clearCurrentUserData={clearCurrentUserData} />} />
        <Route
          path="/app/events"
          element={(
            <Events
              refetchEvents={refetchEvents}
              setRefetchEvents={setRefetchEvents}
              canCreateEvents={can('create', 'events')}
            />
          )}
        />
        <Route path="/app/events/:eventId" element={eventShow()}>
          <Route path=":tabName">
            <Route path=":taskId" />
          </Route>
        </Route>
        <Route
          path="/app/tasks"
          element={(
            <Tasks
              canCreateTasks={can('create', 'tasks')}
              canUpdateLabels={can('update', 'task_labels')}
              canUpdateTemplates={can('update', 'task_templates')}
            />
          )}
        >
          <Route path=":taskId" />
          <Route path="templates">
            <Route path=":templateId" />
          </Route>
        </Route>
        <Route path="/app/settings" element={settings()}>
          <Route path="user" element={settings()} />
          {
            can('update', 'vendors') && (
              <>
                <Route path="vendor" element={settings()} />
                <Route path="contacts" element={settings()} />
              </>
            )
          }
          { can('update', 'permissions') && <Route path="permissions" element={settings()} /> }
          { can('update', 'team_members') && <Route path="team-members" element={settings()} /> }
          { can('update', 'team_member_groups') && <Route path="groups" component={settings()} /> }
        </Route>
        <Route
          path="/app/vendors"
          element={(
            <Vendors />
          )}
        />
      </Route>
    </Routes>
  );
}

export default Router;
