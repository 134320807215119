import gql from 'graphql-tag';

const TEAM_MEMBER_ACTOR_UPDATE_MUTATION = gql`
  mutation UpdateActor($input: ActorUpdateMutationInput!) {
    actorUpdate(input: $input) {
      actor {
        id
        firstName
        lastName
        name
        initials
        avatarUrl
        email
        phoneNumber
        instagramHandle
        actorType
      }
    }
  }
`;

export {
  TEAM_MEMBER_ACTOR_UPDATE_MUTATION,
};
