import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import map from 'lodash/map';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { showSuccessToast, showErrorToast } from 'utils/toastUtils';
import { confirmDialog } from 'primereact/confirmdialog';
import VendorCard from 'containers/Vendors/Index/VendorCard';
import AddVendorModal from './AddVendorModal';
import { EVENT_VENDORS_QUERY, EVENT_INVITE_UPDATE_MUTATION } from './graphql';

const STATUSES_TO_HEADERS = {
  REQUESTED: 'Requested to Join',
  APPROVED: 'Active',
  ADDED: 'Needs Invite',
  INVITED: 'Invite Sent',
  REJECTED: 'Invite Rejected',
  REMOVED: 'Removed',
};
function VendorsTab() {
  const navigate = useNavigate();
  const [showAddVendorModal, setShowAddVendorModal] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isRemovingSelf, setIsRemovingSelf] = useState(false);
  const [isCancellingInvite, setIsCancellingInvite] = useState(false);
  const [currentVendor, setCurrentVendor] = useState(null);
  const [mappedEventVendorsByStatus, setMappedEventVendorsByStatus] = useState([]);
  const [eventVendors, setEventVendors] = useState([]);
  const { eventId } = useParams();
  const toastRef = useRef(null);

  /* eslint-disable no-param-reassign */
  const mapEventVendorsByStatus = eventVendors.reduce((eventVendorsByStatus, eventVendor) => {
    eventVendorsByStatus[eventVendor.status] ||= [];
    eventVendorsByStatus[eventVendor.status].push(eventVendor);
    return eventVendorsByStatus;
  }, {});
  /* eslint-enable no-param-reassign */

  useEffect(() => {
    setMappedEventVendorsByStatus(mapEventVendorsByStatus);
  }, [eventVendors]);

  const [
    fetchEventVendors,
  ] = useLazyQuery(
    EVENT_VENDORS_QUERY,
    {
      onCompleted: (data) => {
        setCurrentVendor(data.currentVendor);
        setEventVendors(map(data.currentActor.eventVendors.edges, 'node'));
      },
      onError: ({ graphQLErrors }) => {
        graphQLErrors.map(({ message }) => (
          showErrorToast(toastRef, `Error fetching event vendors ${message}`)
        ));
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    },
  );

  const refetchEventVendors = () => fetchEventVendors({ variables: { filters: { event: { id: eventId } } } });

  useEffect(() => {
    refetchEventVendors();
  }, []);

  const [updateEventInviteMutation] = useMutation(EVENT_INVITE_UPDATE_MUTATION, {
    onCompleted: () => {
      refetchEventVendors();

      if (isRemoving) {
        if (isRemovingSelf) {
          navigate('/app/events');
        } else {
          showSuccessToast(toastRef, 'Removed');
        }
      } else if (isCancellingInvite) {
        showSuccessToast(toastRef, 'Invite cancelled');
      } else {
        showSuccessToast(toastRef, 'Sent!');
      }

      setIsRemoving(false);
      setIsRemovingSelf(false);
      setIsCancellingInvite(false);
    },
    onError: () => showErrorToast(toastRef, 'Something went wrong :('),
  });

  const updateEventInvite = ({ eventVendor, status = 'INVITED' }) => {
    updateEventInviteMutation({ variables: { input: { eventVendorId: eventVendor.id, status } } });
  };

  const confirmRemoveVendor = ({ eventVendor }) => {
    let message = '';
    let header = '';

    if (eventVendor.vendor.id === currentVendor?.id) {
      setIsRemovingSelf(true);
      message = `Are you sure you want to remove ${eventVendor.vendor.name} from this event? You will immediately lose access.`;
      header = 'Leave Event';
    } else if (eventVendor.status === 'APPROVED') {
      message = `Are you sure you want to remove ${eventVendor.vendor.name}? They will immmediately lose access to this event.`;
      header = 'Remove Vendor';
    } else if (eventVendor.status === 'INVITED') {
      message = `Are you sure you want to cancel sending invite to ${eventVendor.vendor.name}? They will not be able to access this event.`;
      header = 'Cancel Invite';
    } else {
      message = `Are you sure you want to remove ${eventVendor.vendor.name}? They will not be able to access to this event.`;
      header = 'Remove Vendor';
    }

    confirmDialog({
      key: `${eventVendor.id}-remove-link`,
      className: 'xl:w-3/12',
      message,
      dismissableMask: true,
      draggable: false,
      header,
      icon: 'pi pi-exclamation-circle',
      accept: () => {
        if (['APPROVED', 'ADDED'].includes(eventVendor.status)) {
          setIsRemoving(true);
          updateEventInvite({ eventVendor, status: 'REMOVED' });
        } else {
          setIsCancellingInvite(true);
          updateEventInvite({ eventVendor, status: 'ADDED' });
        }
      },
    });
  };

  const confirmSendInvite = (eventVendor) => {
    const { primaryPointOfContactActor } = eventVendor;

    const statusAction = eventVendor.status === 'ADDED' ? 'Send' : 'Re-send';

    return confirmDialog({
      key: `${primaryPointOfContactActor.id}-invite-link`,
      message: `${statusAction} invite to ${primaryPointOfContactActor.name} at ${eventVendor.vendor.name}?`,
      className: 'xl:w-3/12',
      dismissableMask: true,
      draggable: false,
      header: 'Send Invite Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => updateEventInvite({ eventVendor }),
    });
  };

  const showHeaders = Object.keys(mappedEventVendorsByStatus).length > 1;
  let i = 0;

  return (
    <>
      <div className="flex justify-between">
        <p className="font-bold text-xl">Vendors</p>
        {
          true && (
            <Button
              rounded
              className="h-8 w-8"
              icon="pi pi-plus"
              onClick={() => setShowAddVendorModal(true)}
            />
          )
        }
      </div>
      <div className="content-with-nav-bar">
        {
          map(STATUSES_TO_HEADERS, (header, status) => {
            const mappedEventVendors = mappedEventVendorsByStatus[status];

            if (!mappedEventVendors) { return null; }

            i += 1;

            return (
              <div key={`${status}-vendors`}>
                { showHeaders && <p className={`text-gray font-bold ${i > 1 ? 'mt-4' : 'mt-0'}`}>{header}</p> }
                <div className={`${mappedEventVendors.length > 3 ? 'grid' : 'flex'} grid-cols-[repeat(auto-fit,minmax(17rem,1fr))] gap-1 mt-10`}>
                  {
                    mappedEventVendors.map((eventVendor) => (
                      <div className="xs:w-full sm:w-72" key={`${eventVendor.id}-vendor-card`}>
                        <VendorCard
                          vendor={eventVendor.vendor}
                          toastRef={toastRef}
                          eventVendors={[eventVendor]}
                          currentVendor={currentVendor}
                          sendInvite={() => confirmSendInvite(eventVendor)}
                          confirmRemoveVendor={() => confirmRemoveVendor({ eventVendor })}
                          refetchVendors={refetchEventVendors}
                        />
                      </div>
                    ))
                  }
                </div>
              </div>
            );
          })
        }
      </div>
      <AddVendorModal
        show={showAddVendorModal}
        refetchEventVendors={refetchEventVendors}
        onHide={() => setShowAddVendorModal(false)}
        eventId={eventId}
        confirmSendInvite={confirmSendInvite}
        updateEventInvite={updateEventInvite}
        existingEventVendors={map(eventVendors, 'vendor')}
      />
      <Toast ref={toastRef} position="bottom-left" />
    </>
  );
}

export default VendorsTab;
