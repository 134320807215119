import React, { useEffect, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';

import { showSuccessToast, showErrorToast } from 'utils/toastUtils';
import DropdownField from 'components/Form/Dropdown';
import { actorItemTemplate, isSubmittableArea } from 'utils/formUtils';
import { EVENT_VENDOR_UPDATE_MUTATION } from '../graphql';

function UpdateEventVendorContacts({
  toastRef, hideOnBlur, vendor, eventVendor, setIsEditing, refetch,
}) {
  const formRef = useRef();

  const {
    control,
    setValue,
    getValues,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      primaryPointOfContactActorId: eventVendor.primaryPointOfContactActor?.id || vendor.primaryPointOfContactActor?.id,
      secondaryPointOfContactActorId: eventVendor.secondaryPointOfContactActor?.id,
      dayOfPointOfContactActorId: eventVendor.dayOfPointOfContactActor?.id,
    },
  });

  const [updateEventVendorMutation] = useMutation(EVENT_VENDOR_UPDATE_MUTATION, {
    onCompleted: async ({ eventVendorUpdate }) => {
      await refetch({ variables: { filters: { id: { eq: eventVendor.id } } } });
      showSuccessToast(toastRef, `Successfully updated contacts on ${eventVendorUpdate.eventVendor.eventName}`);
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, `Error updating vendor: ${message}`)
      ));
    },
  });

  useEffect(() => {
    const handleBlur = async (e) => {
      if (hideOnBlur && isSubmittableArea(formRef, e)) {
        if (isDirty) {
          await updateEventVendorMutation({
            variables: {
              input: {
                id: eventVendor.id,
                primaryPointOfContactActorId: getValues('primaryPointOfContactActorId'),
                secondaryPointOfContactActorId: getValues('secondaryPointOfContactActorId'),
                dayOfPointOfContactActorId: getValues('dayOfPointOfContactActorId'),
              },
            },
          });
        }

        setIsEditing(false);
      }
    };

    document.addEventListener('mousedown', handleBlur);

    return () => {
      document.removeEventListener('mousedown', handleBlur);
    };
  }, [isDirty, hideOnBlur]);

  const allActors = (vendor.teamMemberActors?.edges || []).map(({ node }) => ({
    label: node.name, value: node.id, name: node.name, initials: node.initials, avatarUrl: node.avatarUrl,
  }));

  return (
    <div className="mt-4">
      <div ref={formRef} className="md:grid md:grid-cols-3 gap-2 w-full mt-4">
        <DropdownField
          control={control}
          name="primaryPointOfContactActorId"
          dropdownClassName="h-12"
          label="Primary"
          options={allActors}
          inputProps={{
            placeholder: 'Select a primary point of contact',
            itemTemplate: (option) => actorItemTemplate({ option }),
            valueTemplate: (option) => actorItemTemplate({ option, placeholder: 'Select a primary point of contact actor' }),
          }}
        />
        <DropdownField
          control={control}
          name="secondaryPointOfContactActorId"
          dropdownClassName="h-12"
          label="Secondary"
          required={false}
          options={allActors}
          onChange={(value) => (value ? {} : setValue('secondaryPointOfContactActorId', null))}
          inputProps={{
            showClear: true,
            placeholder: 'Select a secondary point of contact',
            itemTemplate: (option) => actorItemTemplate({ option }),
            valueTemplate: (option) => actorItemTemplate({ option, placeholder: 'Select a secondary point of contact actor' }),
          }}
        />
        <DropdownField
          control={control}
          name="dayOfPointOfContactActorId"
          dropdownClassName="h-12"
          label="Day Of"
          required={false}
          options={allActors}
          onChange={(value) => (value ? {} : setValue('dayOfPointOfContactActorId', null))}
          inputProps={{
            showClear: true,
            placeholder: 'Select a day of point of contact',
            itemTemplate: (option) => actorItemTemplate({ option }),
            valueTemplate: (option) => actorItemTemplate({ option, placeholder: 'Select a day of point of contact actor' }),
          }}
        />
      </div>
    </div>
  );
}

export default UpdateEventVendorContacts;
