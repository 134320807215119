import React from 'react';

import {
  formatDateTimeStr, emailLink, phoneLink, instagramLink, urlLink,
} from 'utils/stringUtils';

const formatTimes = ({ startTime, endTime }) => {
  const formattedStartTime = startTime ? formatDateTimeStr(startTime, 'h:mm a') : '';
  const formattedEndTime = formatDateTimeStr(endTime, 'h:mm a');

  if (endTime) {
    return (
      `${formattedStartTime} - ${formattedEndTime}`
    );
  }
  return formattedStartTime;
};

const addressLine = (address, field) => {
  if (!address) { return ''; }

  let fieldInfo = address[field];

  if (field === 'cityAndState') {
    if (address.city && address.usState) {
      fieldInfo = `${address.city}, ${address.usState.abbreviation}`;
    } else {
      fieldInfo = address.city || address.usState?.abbreviation;
    }
  }

  return fieldInfo || '';
};

/* eslint-disable max-len */
const formattedAddress = ({ address, showEmpty }) => {
  if (!address && showEmpty) { return 'Add Address'; }

  return `${addressLine(address, 'street1')} ${addressLine(address, 'street2')}\n ${addressLine(address, 'cityAndState')} ${addressLine(address, 'zipCode')}`;
};
/* eslint-enable max-len */

const mapInfo = ({
  data, field, isFormatted = false, icon, className = 'text-sm', iconClassName = 'mr-2', shouldLink = true, showEmpty = false,
}) => {
  const fieldData = isFormatted ? data.formatted : data[field];

  if (!fieldData && !showEmpty) { return null; }
  let formattedField;

  switch (field) {
    case 'address':
      formattedField = (
        <a
          className={className}
          target="_blank"
          href={shouldLink ? `http://www.google.com/search?q=${formattedAddress({ address: data[field], showEmpty })}` : null}
          rel="noreferrer"
        >
          {
            isFormatted ? (
              fieldData || <p className="italic text-xs">Add Address</p>
            ) : formattedAddress({ address: fieldData, showEmpty })
          }
        </a>
      );
      break;
    case 'email':
      formattedField = emailLink({
        email: fieldData, className, iconClassName, shouldLink, showEmpty,
      });
      break;
    case 'websiteUrl':
      formattedField = urlLink({
        url: fieldData, className, iconClassName, shouldLink, showEmpty,
      });
      break;
    case 'phoneNumber':
      formattedField = phoneLink({
        phone: fieldData, className, iconClassName, shouldLink, showEmpty,
      });
      break;
    case 'instagramHandle':
      formattedField = instagramLink({
        instagramHandle: fieldData, className, iconClassName, shouldLink, showEmpty,
      });
      break;
    default:
      formattedField = fieldData;
      break;
  }

  return (
    <div className={`flex items-center ${className}`}>
      <i className={`pi pi-${icon} mr-2 ${iconClassName}`} />
      <p className="m-0">{ formattedField }</p>
    </div>
  );
};

export {
  mapInfo,
  formatTimes,
};
