import React, { useState, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { classNames } from 'primereact/utils';
import { useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import reduce from 'lodash/reduce';
import MultiSelect from 'components/Form/MultiSelect';
import AutoComplete from 'components/Form/AutoComplete';
import { showSuccessToast, showErrorToast } from 'utils/toastUtils';
import { titleize } from 'utils/stringUtils';
import CreateTeamMemberModal from 'containers/Vendors/Index/VendorProfile/CreateTeamMemberForm/Modal';
import CreateVendorForm from './CreateVendorForm';
import { VENDORS_QUERY, EVENT_VENDOR_ADD_MUTATION, VENDOR_CREATE_MUTATION } from './graphql';

function AddVendorModal(props) {
  const [vendors, setVendors] = useState([]);
  const [suggestionVendors, setSuggestionVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [activeEventVendor, setActiveEventVendor] = useState(null);
  const [showFullForm, setShowFullForm] = useState(false);
  const [showCreateTeamMemberModal, setShowCreateTeamMemberModal] = useState(false);

  const toastRef = useRef(null);
  const {
    show,
    onHide,
    eventId,
    existingEventVendors,
    confirmSendInvite,
    updateEventInvite,
    refetchEventVendors,
  } = props;

  const gridClassNames = classNames(
    { 'grid grid-cols-2 gap-2': selectedVendor },
  );

  const {
    control, handleSubmit, reset, setValue,
  } = useForm();

  const {
    vendorsQueryLoading, vendorsQueryError,
  } = useQuery(
    VENDORS_QUERY,
    {
      onCompleted: ({ vendorsSearch }) => {
        const vendorOptions = vendorsSearch.map((vendor) => (
          {
            label: vendor.name,
            value: vendor.id,
            categories: vendor.categories,
          }
        ));
        setVendors(vendorOptions);
      },
    },
  );

  const resetFormAndToggleShowFullForm = ({ showFullFormValue }) => {
    setSelectedVendor(null);
    setActiveEventVendor(null);
    setShowCreateTeamMemberModal(false);
    setSuggestionVendors([]);
    setShowFullForm(showFullFormValue);
    reset();
  };

  const onSelectVendor = (vendor) => {
    setSelectedVendor(vendor);
    setValue('categories', vendor.categories);
  };

  const resetFormAndCloseModal = () => {
    resetFormAndToggleShowFullForm({ showFullFormValue: false });
    onHide();
  };

  const [addEventVendorMutation] = useMutation(EVENT_VENDOR_ADD_MUTATION, {
    onCompleted: ({ eventVendorAdd }) => {
      const { eventVendor } = eventVendorAdd;
      const { primaryPointOfContactActor } = eventVendor;

      if (!primaryPointOfContactActor) {
        setActiveEventVendor(eventVendor);
        setShowCreateTeamMemberModal(true);
      } else {
        confirmSendInvite(eventVendor);
        resetFormAndCloseModal();
        refetchEventVendors();
      }
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, `Error adding vendor to event: ${message}`)
      ));
    },
  });

  const [createVendorMutation] = useMutation(VENDOR_CREATE_MUTATION, {
    onCompleted: ({ vendorCreate }) => {
      showSuccessToast(toastRef, `Successfully created vendor ${vendorCreate.vendor.name}`);
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, `Error creating vendor: ${message}`)
      ));
    },
  });

  const onSubmit = async (values) => {
    if (values.vendorsAutocomplete?.value) {
      // AutoComplete form filled out, submit with our vendor
      await addEventVendorMutation({
        variables: {
          input: {
            vendorId: values.vendorsAutocomplete.value,
            eventId,
            categories: values.categories,
          },
        },
      });
    } else {
      const address = {
        street1: values.street1,
        street2: values.street2,
        city: values.city,
        usStateId: values.state,
        zipCode: values.zipCode?.toString(),
      };

      // Submit the full form to create a new vendor in our database
      const { data } = await createVendorMutation({
        variables: {
          input: {
            name: values.name,
            googlePlaceId: values.googlePlaceId,
            categories: values.categories,
            address,
          },
        },
      });

      if (data) {
        const vendorId = data.vendorCreate.vendor.id;

        await addEventVendorMutation({
          variables: {
            input: {
              vendorId,
              eventId,
              categories: values.categories,
            },
          },
        });
      }
    }
  };

  const searchVendors = (event) => {
    // From the list of all vendors, filter out if the event already has it
    // and then filter out any that don't match the text
    // TODO: move to backend
    const filteredVendors = vendors.filter((vendor) => (
      !existingEventVendors.find((eventVendor) => eventVendor.id === vendor.value)
      && vendor.label.toLowerCase().match(event.query.toLowerCase())
    ));

    if (filteredVendors.length > 0) {
      setSuggestionVendors(filteredVendors);
    } else {
      setSuggestionVendors([{ id: null, label: 'No results found' }]);
    }
  };

  if (vendorsQueryLoading || vendorsQueryError) { return null; }

  const mappedCategories = () => (
    reduce(selectedVendor.categories, (options, category) => {
      options.push({ label: titleize(category), value: category });
      return options;
    }, [])
  );

  const onHideCreateTeamMemberModal = () => {
    resetFormAndCloseModal();
    refetchEventVendors();
  };

  if (showCreateTeamMemberModal) {
    return (
      <CreateTeamMemberModal
        show={showCreateTeamMemberModal}
        refetchEventVendor={refetchEventVendors}
        sendInvite={updateEventInvite}
        eventVendor={activeEventVendor}
        onHide={onHideCreateTeamMemberModal}
        toastRef={toastRef}
      />
    );
  }

  return (
    <>
      <Toast ref={toastRef} position="bottom-left" />
      <Dialog
        header="Add vendor to event"
        className="xl:w-7/12"
        visible={show}
        onHide={resetFormAndCloseModal}
        resizable={false}
        dismissableMask
        draggable={false}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {
            showFullForm ? (
              <>
                <CreateVendorForm control={control} setValue={setValue} toastRef={toastRef} />
                <div className="w-full flex justify-start mt-4">
                  <Button
                    label="Search existing vendors"
                    icon="pi pi-arrow-left"
                    text
                    type="button"
                    onClick={() => resetFormAndToggleShowFullForm({ showFullFormValue: false })}
                  />
                </div>
              </>
            ) : (
              <>
                <div className={`grid ${gridClassNames}`}>
                  <AutoComplete
                    focus
                    control={control}
                    name="vendorsAutocomplete"
                    inputClassName="h-12"
                    label="Search vendors"
                    suggestions={suggestionVendors}
                    completeMethod={searchVendors}
                    field="label"
                    onSelect={(e) => onSelectVendor(e.value)}
                  />
                  {
                    selectedVendor && (
                      <MultiSelect
                        control={control}
                        name="categories"
                        label="Categories"
                        options={mappedCategories()}
                        showSelectAll={false}
                        placeholder="Select a category"
                      />
                    )
                  }
                </div>
                <div className="flex w-full justify-center mt-4">
                  <Button
                    label="Can't find the vendor you're looking for? Create new vendor."
                    text
                    className="create-vendor-button"
                    type="button"
                    onClick={() => resetFormAndToggleShowFullForm({ showFullFormValue: true })}
                  />
                </div>
              </>
            )
          }
          <div className="w-full flex justify-end">
            <Button
              size="small"
              label="Add"
              type="submit"
            />
          </div>
        </form>
      </Dialog>
    </>
  );
}

export default AddVendorModal;
