import gql from 'graphql-tag';

const VENDOR_UPDATE_MUTATION = gql`
  mutation UpdateVendor($input: VendorUpdateMutationInput!) {
    vendorUpdate(input: $input) {
      vendor {
        id
        name
        internalNotes
        phoneNumber
        instagramHandle
        email
        websiteUrl
        teamMemberActors {
          edges {
            node {
              id
              firstName
              lastName
              name
              initials
              avatarUrl
              email
              phoneNumber
              instagramHandle
              actorType
            }
          }
        }
        address {
          id
          formatted
          street1
          street2
          city
          usState {
            id
            abbreviation
          }
          zipCode
        }
        primaryPointOfContactActor {
          id
          firstName
          lastName
          name
          initials
          avatarUrl
          email
          phoneNumber
          instagramHandle
          actorType
        }
        avatarUrl
        categories
        isUpdatableByCurrentActor
        reviewSources {
          id
          name
        }
      }
    }
  }
`;

const EVENT_VENDOR_UPDATE_MUTATION = gql`
  mutation UpdateEventVendor($input: EventVendorUpdateMutationInput!) {
    eventVendorUpdate(input: $input) {
      eventVendor {
        id
        eventName
        notes
        internalNotes
        primaryPointOfContactActor {
          id
          firstName
          lastName
          name
          initials
          avatarUrl
          email
          phoneNumber
          instagramHandle
          actorType
        }
        secondaryPointOfContactActor {
          id
          firstName
          lastName
          name
          initials
          avatarUrl
          email
          phoneNumber
          instagramHandle
          actorType
        }
        dayOfPointOfContactActor {
          id
          firstName
          lastName
          name
          initials
          avatarUrl
          email
          phoneNumber
          instagramHandle
          actorType
        }
        vendor {
          id
          name
          notes
          phoneNumber
          instagramHandle
          email
          websiteUrl
          address {
            id
            formatted
            street1
            street2
            city
            usState {
              id
              abbreviation
            }
            zipCode
          }
          teamMemberActors {
            edges {
              node {
                id
                firstName
                lastName
                name
                initials
                avatarUrl
                email
                phoneNumber
                instagramHandle
                actorType
              }
            }
          }
          primaryPointOfContactActor {
            id
            firstName
            lastName
            name
            initials
            avatarUrl
            email
            phoneNumber
            instagramHandle
            actorType
          }
          internalNotes
          avatarUrl
          categories
          isUpdatableByCurrentActor
          reviewSources {
            id
            name
          }
        }
      }
    }
  }
`;
export {
  VENDOR_UPDATE_MUTATION,
  EVENT_VENDOR_UPDATE_MUTATION,
};
