import React, { useEffect, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import reduce from 'lodash/reduce';
import { FileUpload } from 'primereact/fileupload';
import { Avatar } from 'primereact/avatar';

import { titleize } from 'utils/stringUtils';
import { showSuccessToast, showErrorToast } from 'utils/toastUtils';
import TextInput from 'components/Form/TextInput';
import MultiSelect from 'components/Form/MultiSelect';
import { isSubmittableArea } from 'utils/formUtils';
import { VENDOR_UPDATE_MUTATION } from '../graphql';

function UpdateVendorBasicInfo({
  toastRef, vendor, setUpdatedVendor, setIsEditing,
}) {
  const formRef = useRef();

  const {
    control,
    setValue,
    getValues,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      avatarUrl: vendor.avatarUrl,
      name: vendor.name,
      categories: vendor.categories,
    },
  });

  const [updateVendorMutation] = useMutation(VENDOR_UPDATE_MUTATION, {
    onCompleted: ({ vendorUpdate }) => {
      setValue('avatarUrl', vendorUpdate.vendor.avatarUrl);
      setUpdatedVendor(vendorUpdate.vendor);
      showSuccessToast(toastRef, `Successfully updated vendor ${vendorUpdate.vendor.name}`);
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, `Error updating vendor: ${message}`)
      ));
    },
  });

  useEffect(() => {
    const handleBlur = async (e) => {
      if (isSubmittableArea(formRef, e)) {
        if (isDirty) {
          await updateVendorMutation({
            variables: {
              input: {
                id: vendor.id,
                categories: getValues('categories'),
                name: getValues('name'),
              },
            },
          });
        }

        setIsEditing(false);
      }
    };

    document.addEventListener('mousedown', handleBlur);

    return () => {
      document.removeEventListener('mousedown', handleBlur);
    };
  }, [isDirty]);

  const avatarUpload = () => (
    <div className="flex flex-col">
      <Avatar image={watch('avatarUrl')} size="xlarge" shape="circle" className="w-52 h-52" />
      <div className="flex w-full justify-center">
        <FileUpload
          mode="basic"
          chooseOptions={{ icon: 'pi pi-pencil', iconOnly: true, className: '-mt-4 border-circle p-3 button-icon-only' }}
          onSelect={({ files }) => updateVendorMutation({ variables: { input: { id: vendor.id, avatar: files[0] } } })}
        />
      </div>
    </div>
  );

  const vendorCategories = reduce(vendor.categories, (options, category) => {
    options.push({ label: titleize(category), value: category });
    return options;
  }, []);

  return (
    <div className="mt-4 flex w-1/2 text-sm text-default">
      <div ref={formRef} className="mt-4 flex items-center w-full">
        <div className="mr-2">
          { avatarUpload() }
        </div>
        <div className="w-full">
          <TextInput
            control={control}
            name="name"
            label="Name"
          />
          <MultiSelect
            control={control}
            name="categories"
            label="Categories"
            options={vendorCategories}
            showSelectAll={false}
            placeholder="Select a category"
          />
        </div>
      </div>
    </div>
  );
}

export default UpdateVendorBasicInfo;
